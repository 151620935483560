// src/components/AudioClips.jsx

import React from 'react';
import '../styles/AudioClips.css';

// importing audio files
import amcAudio from '../assets/audio/AMC-sample.mp3';
import axeAudio from '../assets/audio/Axe-sample.mp3';
import gameflyAudio from '../assets/audio/Gamefly-sample.mp3';
import purdyPaint from '../assets/audio/Purdy-Paint.mp3';
import shopifyAudio from '../assets/audio/Shopify-Sample.mp3';
import heroAudio from '../assets/audio/Hero-sample.mp3';
import captainAudio from '../assets/audio/Platoon-Captain.mp3';
import scientistAudio from '../assets/audio/Scientist-Warrior.mp3';
import warriorAudio from '../assets/audio/Warrior-Friend.mp3';
import southernAudio from '../assets/audio/Southern-Mystery.mp3';
import maleAudio from '../assets/audio/Various-Male-Characters.mp3';

// Import Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilm, faMicrophone, faUser, faTools, faCartPlus, faPaintBrush, faShieldAlt, faFlask } from '@fortawesome/free-solid-svg-icons';

const AudioClips = () => {
    const clips = [
        {
            id: 1,
            title: 'AMC Sample',
            src: amcAudio,
            icon: faFilm,  // Using the film icon for AMC
        },
        {
            id: 2,
            title: 'Axe Sample',
            src: axeAudio,
            icon: faMicrophone,  // Using a microphone icon for voiceovers
        },
        {
            id: 3,
            title: 'GameFly Ad',
            src: gameflyAudio,
            icon: faTools,  // Tools icon for the GameFly Ad
        },
        {
            id: 4,
            title: 'Purdy Paint Ad',
            src: purdyPaint,
            icon: faPaintBrush,  // Paintbrush icon for Purdy Paint
        },
        {
            id: 5,
            title: 'Shopify Ad',
            src: shopifyAudio,
            icon: faCartPlus,  // Shopping cart icon for Shopify
        },
        {
            id: 6,
            title: 'The Hero',
            src: heroAudio,
            icon: faShieldAlt,  // Shield icon for The Hero
        },
        {
            id: 7,
            title: 'The Captain',
            src: captainAudio,
            icon: faShieldAlt,  // Shield icon for The Captain
        },
        {
            id: 8,
            title: 'The Scientist',
            src: scientistAudio,
            icon: faFlask,  // Flask icon for The Scientist
        },
        {
            id: 9,
            title: 'Your Friend The Warrior',
            src: warriorAudio,
            icon: faShieldAlt,  // Shield icon for The Warrior
        },
        {
            id: 10,
            title: 'Southern Mystery',
            src: southernAudio,
            icon: faUser,  // User icon for Southern Mystery (representing character)
        },
        {
            id: 11,
            title: 'Male Characters',
            src: maleAudio,
            icon: faUser,  // User icon for Male Characters
        },
    ];

    return (
        <section className='audio-clips'>
            <h2>Voice Clips</h2>
            <div className='clips-container'>
                {clips.map((clip) => (
                    <div key={clip.id} className='clip'>
                        <FontAwesomeIcon icon={clip.icon} className="clip-icon" />
                        <h3>{clip.title}</h3>
                        <audio controls>
                            <source src={clip.src} type='audio/mpeg' />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default AudioClips;
