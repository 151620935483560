import React from 'react';
import '../styles/About.css';
import kadenPicture from '../assets/images/Kaden-headshot.jpeg';

const About = () => {
    return (
        <section className='about'>
            <div className='about-container'>
                <div className='about-image'>
                    <img src={ kadenPicture } alt='Portrait of Kaden Curtis'/>
                </div>
                <div className='about-text'>
                    <h2>About Me</h2>
                    <p>From an early age, Kaden discovered that he had a gift and passion for using his voice.
                        He would use music, a tender word, or funny accents to bring joy, comfort, or a smile to those around him; but it wasn’t until high school when he learned he could make a career out of just that.
                        A dream was dreamt and goals were made to make that his full-time job. Kaden has been harnessing his craft for 15 years, but has only recently broken into the wide world of professional voiceover.
                        With his smooth, baritone voice, he can get you whatever voice acting product you need whether it’s comedy, drama, or character acting. He’s hungry and eager to work, and is willing to go the extra mile to achieve his dream. 
                        He currently uses a Sennheiser MKE microphone and an Apollo Twin interface with Adobe Audition as his software of choice. He is available on his Source Connect.</p>
                </div>
            </div>
        </section>
    );
};

export default About;