import React, { useState } from 'react';
import '../styles/ContactForm.css';

const ContactForm = () => {
    const [ formData, setFormData ] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [ submitted, setSubmitted ] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [ name ]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        setSubmitted(true)
        // Reset post submission
        setFormData({ name: '', email: '', message: '' });
    };

    return (
        <section className='contact-form'>
            <h2>Contact Me</h2>
            { submitted ? (
                <p className='success-message'>Thank you! Your message was sent!</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label htmlFor='name'>Name</label>
                        <input
                        type='text'
                        id='name'
                        name='name'
                        value={ formData.name }
                        onChange={ handleChange }
                        required
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='email'>Email</label>
                        <input
                        type='email'
                        id='email'
                        name='email'
                        value={ formData.email }
                        onChange={ handleChange }
                        required
                        />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='message'>Message</label>
                        <textarea
                        id='message'
                        name='message'
                        value={ formData.message }
                        onChange={ handleChange }
                        required
                        ></textarea>
                    </div>

                    <button type='submit'> Send Message</button>
                </form>
            )}
        </section>
    );
};

export default ContactForm;