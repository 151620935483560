import React from 'react';
import '../styles/Footer.css'

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer-container'>
                <p>&copy; {new Date().getFullYear()} Kaden Curtis. All Rights Reserved.</p>
                <div className='footer-social'>
                    <a href='https://twitter.com' target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-twitter'></i>
                    </a>

                    <a href='https://instagram.com' target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-instagram'></i>
                    </a>

                    <a  href='https://facebook.com' target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-facebook'></i>
                    </a>

                    <a href='https://linkedin.com' target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-linkedin'></i>
                    </a>

                </div>
            </div>
        </footer>
    );
};

export default Footer;